import axios from "axios";
import { toast } from "react-toastify";
import { Sky_API_URL_UAT, Sky_API_URL_Production } from "./constants";

const urlParams = new URLSearchParams(window.location.search);
const api_key = urlParams.get('api_key');
const token_id = urlParams.get('token_id');
const state = urlParams.get('state');

let url = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_UAT_URL : `https://${window.location.host}/oapi/v1`



export const API_CALL = async (endpoint, method, headers, params, data) => {
    const remethod = method.toString();
    if (api_key === null || api_key === undefined || api_key === "") {
        toast.error('No API Key Found')
        return;
    }

    if (token_id === null || token_id === undefined || token_id === "") {
        toast.error('No Token ID Found')
        return;
    }



    const getUserAgent = () => {
        return navigator.userAgent;
    };

    const userAgent = getUserAgent();

    const config = {
        method: remethod,
        url: `${url}${endpoint}`,
        headers: {
            'User-Agent': userAgent,
            ...headers
        },
        params: {
            api_key: api_key,
            token_id: token_id,
            state: state,
            ...params
        },
        data: {
            api_key: api_key,
            token_id: token_id,
            state: state,
            ...data
        }
    }
    try {
        const response = await axios.request(config);
        if (response.status !== 200) {
            return toast.error(response.statusText);
        }
        return response;
    } catch (error) {

        toast.error(error.response.data.error)
        return error.message;
    }
}

export const Dashboard_API_CALL = async (endpoint, method, headers, params, data) => {

    const regex = /(login|create-individual-merchant)/;

    const getUserAgent = () => {
        return navigator.userAgent;
    };

    const userAgent = getUserAgent();


    let config = {
        method: method,
        url: `${url}${endpoint}`,
        headers: {
            'User-Agent': userAgent,
            ...headers
        },
        params: {
            token_id: token_id,
            ...params
        },
        data: {

            ...data
        },
        timeout: 5000
    }
    try {
        const response = await axios.request(config);
        if (response.status !== 200) {
            return toast.error(response.statusText);
        }
        return response;
    } catch (error) {
        // console.log(error.response.data)
        if (error.response) {
            const responseData = error.response.data;
            if (window.location.pathname.split('/').indexOf('dashboard') > -1 && responseData.error === "Unauthorized") {
                localStorage.clear();
                toast.success("You've Been Logged Out")
                return window.location.href = '/oapi/v1/im/login';
            }
            else if (responseData.error) {
                toast.error(responseData.error);
            } else if (responseData.meta) {
                toast.error(responseData.meta.statusMsg);
            } else if (responseData.message) {
                toast.error(responseData.message);
            }
            else {
                toast.error(responseData);
            }
        } else {
            return toast.error(error.message);
        }
        // return error.message;
    }
}