import React, { useState } from 'react'
import Fade from './Fade'
import Modal from './Modal'
import Card from './Card'
import { API_CALL } from '../utilities/function'
import { disclaimerContent } from '../utilities/constants'
import Button from './Button'

const Disclaimer = ({ setOpenDisclaimer, isAuthorize, callbackUrl, requestToken, setAuthorize, params }) => {
    const [isLoading, setLoading] = useState(false)

    const handleClick = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const state = urlParams.get('state');
        console.log(isAuthorize)
        if (!isAuthorize) {
            if (window.location.pathname.split('/').includes('dashboard')) {
                setOpenDisclaimer(false);
                return;
            }
            let fetchedURL = `${callbackUrl}?requestToken=${requestToken}`;
            if (state !== null) {
                fetchedURL = (`${fetchedURL}&state=${state}`)
            }

            window.location.href = fetchedURL;
            // console.log(fetchedURL)
        } else if (isAuthorize) {

            setLoading(true);
            const response = await API_CALL('/authorise', 'get', '', params);
            // const fetchedURL = `${response.data.callbackUrl}?requestToken=${requestToken}`;
            let fetchedURL = `${callbackUrl}?requestToken=${requestToken}`;
            if (state !== null) {
                fetchedURL = (`${fetchedURL}&state=${state}`)
            }
            // console.log(fetchedURL)
            if (response.data.callbackUrl && response.data.requestToken) {
                window.location.href = fetchedURL;
                // console.log(fetchedURL)
                setTimeout(() => {
                    setLoading(false);
                }, 1500)

            }
            // console.log('@@FetchedURL from Resp', fetchedURL);
        }
    }

    return (
        <Modal
            className='lg:w-1/3 max-lg:w-4/5'>
            <Card className={'flex flex-col gap-5 items-center'}>
                <h3 className='font-bold text-base text-center'>Risk Disclosures on Derivatives</h3>
                <ul className='flex flex-col gap-3'>
                    {disclaimerContent.length > 0 && disclaimerContent.map((item, index) => (
                        <li
                            className='text-left text-sm list-disc'
                            key={index}>{item}</li>
                    ))}
                </ul>
                <Button
                    isDisabled={isLoading}
                    onClick={handleClick}
                    className='px-6'
                    text={'I Understand the Risk'} />
                <span
                    onClick={() => { window.open('https://www.sebi.gov.in/legal/circulars/may-2023/risk-disclosure-with-respect-to-trading-by-individual-traders-in-equity-futures-and-options-segment_71426.html') }}
                    className='text-Primary-Blue-100 font-bold cursor-pointer'>View SEBI circular</span>
            </Card>
        </Modal>
    )
}

export default Disclaimer